<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nueva Propuestas</h1>
        <hr class="">

        <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
          <v-row class="mb-4">
            <v-col cols="12" lg="12" class="mt-10">
              <v-text-field
                  v-model="data.namePromotor"
                  label="Nombre del Proveedor"
                  placeholder="Nombre del Proveedor"
                  outlined
              ></v-text-field>
            </v-col>
            <v-expansion-panels inset>
              <v-expansion-panel
              >
                <v-expansion-panel-header>Adjunta documento</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12">
                    <FileInput
                        :tenderId="data.tenderId"
                        :formatosId="data.formatosId"
                        :file="data.urlFile"
                        @getImageFile="handleImageFile"
                        @changeShowImage="handleShowImage"
                    />
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-col cols="12" class="mt-4">
              <v-btn type="submit" color="primary" style="width: 200px">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import FileInput from "../../components/FileInput";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatosId: null,
        urlFile: '',
        namePromotor: "",

      },
    };
  },
  components: {
    FileInput,
    Overlay,
    Trumbowyg
  },
  created() {
    this.data.tenderId = parseInt(this.$route.params.id);
    this.data.formatosId = parseInt(this.$route.params.formatId);

  },
  methods: {
    ...mapActions('proposals', ['createProposals']),
    handleImageFile(file) {
      this.data.urlFile = file;
    },
    handleShowImage(show) {
    },
    async validateRequired() {
      if (!this.data.urlFile) {
        this.sweetAlertWarning('Campo documento es necesario');
        return false
      }
      if (!this.data.namePromotor) {
        this.sweetAlertWarning('Nombre del proveedor es necesario');
        return false
      }

      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createProposals(this.data);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
